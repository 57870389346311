  /*---------------------systemy gazowe --------------*/
  
  .sg_content {
      width: 100%;
      margin: 20px 10px 40px 0px;
      /*background-color:red;*/
      float: left;
      @include breakpoint(tablet) {
          width: 50%;
          margin: 20px 0px 80px 0px;
      }
      @include breakpoint(desktop) {
          width: 470px;
          margin: 20px 10px 80px 0px;
      }
      a.sg {
          background-color: white;
          overflow: hidden;
          font-size: 16px;
          display: block;
          float: left;
          text-transform: uppercase;
          width: 100%;
          padding: 11px 0 0 0px;
          margin: 0 0px 20px 0px;
          @include breakpoint(tablet) {
              width: calc(50% - 10px);
              padding: 4px 0 0 0px;
              margin: 0 10px 20px 0px;
          }
          @include breakpoint(desktop) {
              width: 220px;
              padding: 4px 0 0 10px;
              margin: 0 10px 20px 0px;
          }
          &.right {
              margin-right: 0px;
          }
          &.lpg {
              color: #15ac4e;
              border-top: 2px solid #15ac4e;
              background: url(../img/sg_lpg.png) no-repeat top right;
          }
          &.cng {
              color: #fe8301;
              border-top: 2px solid #fe8301;
              background: url(../img/sg_cng.png) no-repeat top right;
          }
      }
      &.right {
          margin-right: 0px;
          @include breakpoint(tablet) {
              a.sg {
                  margin: 0 0px 20px 10px;
              }
          }
          @include breakpoint(desktop) {
              a.sg {
                  margin: 0 10px 20px 0px;
                  &.right {
                      margin-right: 0px;
                  }
              }
          }
      }
  }
  
  #gazeo_symulator {
      /*background-color:red;*/
      margin: 0 0 0 -10px;
  }
