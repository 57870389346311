    /*----------------calculator-------------------*/
    
    #calculator label {
        margin: 10px 0px 0px 0px;
        width: 190px;
    }
    
    #calculator input {
        width: 178px;
    }
    
    #calculator input[type="submit"] {
        margin: 10px 0px 0px 0px;
        width: 190px;
    }
    
    h1.saving {
        background: url(../img/saving.png) no-repeat top right;
    }