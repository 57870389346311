/*----------------CAR-------------------*/

.container_car {
    height: 300px;
    @include breakpoint(tablet) {
        height: 600px;
    }
    #car {
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }
    #car_landirenzo,
    #car_lovato {
        /*background-color:white;*/
        height: auto;
        position: relative;
        width: 80%;
        margin: 0 10%;
        // opacity: 0.0;
        // top: 50%;
        // transform: translate(0, -50%);
        pointer-events: none;
        &.active {
            pointer-events: all;
        }
        @include breakpoint(desktop) {
            //   top: 0;
            // transform: translate(0, 0%);
            //   height: 600px;
            margin: 0;
            width: 960px;
        }
    }
    #car_lovato {
        opacity: 0.0;
    }
    #car_lovato_img,
    #car_landirenzo_img {
        width: 100%;
        transform: translate(5%, 70%);
        position: absolute;
        @include breakpoint(desktop) {
            width: auto;
            transform: translate(0, 0%);
            margin: 180px 0 0 170px;
        }
    }
    #car_plus_shadow_1,
    #car_plus_shadow_4 {
        top: 254px;
        left: 200px;
    }
    #car_plus_shadow_2,
    #car_plus_shadow_5 {
        top: 234px;
        left: 340px;
    }
    #car_plus_shadow_3,
    #car_plus_shadow_6 {
        top: 274px;
        left: 660px;
    }
    canvas {
        display: none;
        position: absolute;
        bottom: 20px;
        width: 300px;
        height: 200px;
        @include breakpoint(desktop) {
            display: block;
        }
    }
    .car_info {
        display: block;
        width: 100%;
        height: 260px;
        /*background-color:red;*/
        /*opacity:0.5;*/
        position: absolute;
        opacity: 0.0;
        @include breakpoint(desktop) {
            width: 300px;
        }
    }
    .car_info p {
        position: absolute;
        margin: 0px 0 0 0;
        /*display:none;*/
        opacity: 0.0;
        color: #9d9d9d;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        padding: 0;
        @include breakpoint(tablet) {
            margin: 50px 0 0 0;
            font-size: 16px;
            line-height: 24px;
        }
        @include breakpoint(desktop) {
            margin: 50px 0 0 0;
            padding: 10px 0px 10px 0px;
            text-align: left;
            font-size: 14px;
            line-height: 22px;
        }
        strong {
            color: #275e95;
            font-weight: bold;
        }
    }
    #car_info_1 {
        // top: 50px;
        // left: 660px;
        top: 10px;
        left: 10px;
        @include breakpoint(tablet) {
            margin-top: 40px;
        }
        @include breakpoint(desktop) {
            margin-top: 0px;
        }
        #car_p_1 {
            margin: 20px 0 0 0;
            @include breakpoint(desktop) {
                margin: 50px 0 0 0;
            }
        }
    }
    #car_info_2 {
        top: 0px;
        left: 340px;
        #car_p_2 {
            margin: 30px 0 0 30px;
        }
    }
    #car_info_3 {
        top: 50px;
        left: 660px;
        #car_p_3 {
            margin: -10px 0 0 30px;
        }
    }
    #car_info_4 {
        //  top: 10px;
        // left: 10px;
        top: 10px;
        left: 10px;
        @include breakpoint(tablet) {
            margin-top: 40px;
        }
        @include breakpoint(desktop) {
            margin-top: 0px;
        }
        #car_p_4 {
            margin: 20px 0 0 0;
            @include breakpoint(desktop) {
                margin: 30px 0 0 0;
            }
        }
    }
    #car_info_5 {
        top: 0px;
        left: 340px;
        #car_p_5 {
            margin: 10px 0 0 30px;
        }
    }
    #car_info_6 {
        top: 50px;
        left: 660px;
        #car_p_6 {
            margin: 10px 0 0 30px;
        }
    }
    a.car_plus {
        position: absolute;
        bottom: 0px;
        display: none;
        width: 67px;
        height: 52px;
        background: url(../img/plus.png) no-repeat;
        @include breakpoint(desktop) {
            display: block;
        }
    }
    .car_plus_shadow {
        position: absolute;
        display: block;
        width: 67px;
        height: 52px;
        background: url(../img/plus_shadow.png) no-repeat;
        opacity: 0.0;
    }
}

.aplikacja-main {
    background: url(../img/aplikacja-main.png) top center no-repeat;
    width: 277px;
    height: 118px;
    position: absolute;
    right: -340px;
    display: block;
    top: 290px;
    transform: scale(0.7) translate(69px, 0px);
    @include breakpoint(tablet) {
        top: 600px;
        transform: scale(0.8) translate(35px, 0px);
    }
    @include breakpoint(desktop) {
        top: 305px;
        transform: scale(1) translate(0px, 0px);
    }
}

// .promocja-main {
//     background: url(../img/promocje-z-kuponem-main.png) top center no-repeat;
//     width: 277px;
//     height: 118px;
//     position: absolute;
//     top: 305px;
//     left: -340px;
//     display: block;
// }
// .promocja-shell {
//     background: url(../img/promocje-z-shell-main.png) top center no-repeat;
//     width: 277px;
//     height: 140px;
//     position: absolute;
//     top: 520px;
//     left: -340px;
//     display: block;
// }
.pakiet-powitalny-shell {
    background: url(../img/pakiet-powitalny-shell.png) top center no-repeat;
    width: 310px;
    height: 140px;
    position: absolute;
    left: -310px;
    display: block;
    z-index: 10;
    top: 380px;
    transform: scale(0.7) translate(-69px, 0px);
    @include breakpoint(tablet) {
        top: 600px;
        transform: scale(0.8) translate(-40px, 0px);
    }
    @include breakpoint(desktop) {
        top: 305px;
        transform: scale(1) translate(0px, 0px);
    }
}

// .targi-main {
//     background: url(../img/targi-main.png) top center no-repeat;
//     width: 400px;
//     height: 200px;
//     position: absolute;
//     top: 305px;
//     left: -800px;
//     display: block;
// }
