 /*----------------galeria-------------------*/
 
 div.pp_default .pp_expand {
     display: none !important;
     @include breakpoint(desktop) {
         display: inline !important;
     }
 }
 
 .pp_gallery {
     display: none !important;
     @include breakpoint(desktop) {
         display: block !important;
     }
 }


 /* prettyPhoto styling for small screens */
@media (max-width: $size-tablet - 1px)
{
    .pp_pic_holder.pp_default
    {
        width: 100%!important;
        margin-top:-100px !important;
        left: 0!important;
        overflow: hidden;
    }
    div.pp_default .pp_content_container .pp_left
    {
        padding-left: 0!important;
    }
    div.pp_default .pp_content_container .pp_right
    {
        padding-right: 0!important;
    }
    .pp_content
    {
        width: 100%!important;
        height: auto!important;
    }
    .pp_fade
    {
        width: 100%!important;
        height: 100%!important;
    }
    a.pp_expand,
    a.pp_contract,
    .pp_hoverContainer,
    .pp_gallery,
    .pp_top,
    .pp_bottom
    {
        display: none!important;
    }
    #pp_full_res img
    {
        width: 100%!important;
        height: auto!important;
    }
    .pp_details
    {
        box-sizing: border-box;
        width: 100%!important;
        padding-left: 3%;
        padding-right: 4%;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #fff;
        margin-top: -2px!important;
    }
    a.pp_close
    {
        right: 10px!important;
        top: 10px!important;
    }
}
 
 a.galeria {
     color: #808080;
     font-size: 22px;
     padding: 0 0px 0 0px;
     height: auto;
     float: left;
     border-top: 2px solid #077dba;
     background-color: #f4f4f4;
     position: relative;
     width: calc(100% - 40px);
     margin: 0 20px 40px 20px;
     overflow: hidden;
     @include breakpoint(tablet) {
         width: calc(33% - 40px);
         margin: 0 20px 40px 20px;
         height: 330px;
     }
     @include breakpoint(desktop) {
         width: 230px;
         margin: 0 5px 40px 5px;
     }
     img {
         width: 100%;
         height: auto;
         min-height: 154px;
     }
 }
 
 a.galeria .icon {
     background: url(../img/foto.png) top right no-repeat;
     z-index: 10;
     width: 28px;
     height: 28px;
     position: absolute;
     right: 0;
 }
 
 a.galeria .content {
     width: 200px;
     height: 125px;
 }
 
 a.galeria p.title {
     color: #077dba;
     font-size: 14px;
     width: 200px;
     padding: 0 15px 0 15px;
 }
 
 a.galeria p.data {
     color: black;
     font-size: 12px;
     width: 200px;
     padding: 0 15px 0 15px;
 }
 
 a.galeria p.button {
     background-color: #077dba;
     color: white;
     text-align: center;
 }
