.cookies__box {
    position: fixed;
    background: $color-blue;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: none;
    text-align: center;
    padding-bottom: 20px;
    @include breakpoint(tablet) {
       padding-bottom: 0px;
    }
    a:visited {
        color: $color-white;
        text-decoration: none;
    }
    a:link {
        color:$color-white;
        text-decoration: none;
    }
    a:link {
        color:$color-white;
        text-decoration: none;
    }
    .cookies__box__center {
        width: calc(100% - 40px);
        height: auto;
        display: inline-block;
        text-align: center;



        @include breakpoint(tablet) {
            width: calc(100% - 80px);
            height: 60px;
            text-align: right;
        }

        @include breakpoint(desktop) {
            width: calc(100% - 200px);
             height: 40px;
        }
        p {
            width: auto;
            color: $color-white;
            font-size:14px;
            line-height:20px;
            letter-spacing: 0.05em;
            margin: 0px 40px 0 0;
            display: inline-block;
            vertical-align: top;
            margin: 20px 0px 10px 0;
            padding:0;

            @include breakpoint(tablet) {
                text-align: right;
                margin: 10px 40px 0 0;
                font-size:14px;
                line-height:20px;
                width: 500px;
            }
            @include breakpoint(desktop) {
                width: auto;
                text-align: center;
                margin: 0px 40px 0 0;
                font-size:14px;
                line-height:40px;
            }

            a {
                position: relative;
                &::after {
                    content: ""; // color: $color-link;
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                    right: 0;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    transition: transform 0.2s;
                    transform-origin: left;
                }
                // &:hover {
                //     &::after {
                //         transform: scaleX(0);
                //     }
                // }
            }
        }
        .button {
            width: auto;
           // min-height: 40px;
            position: relative;
            background-color: #658ec6;
            color: #fff;
            transition: 0.25s;
            overflow: hidden;
            vertical-align: middle;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            text-align: center;
            padding: 0px 20px;
            display: inline-block;
            cursor: pointer;
            text-transform: uppercase;
        }

        .cookies__box__closer {
            margin: 10px 0 0px 0px;
            background-color: transparent;
            border: 1px solid $color-white;
            color: $color-white;
            @include breakpoint(tablet) {
                margin: 15px 0 0px 0px;
            }
            @include breakpoint(desktop) {
                margin: 5px 0 0px 0px;
            }
            &:hover {
                background-color: $color-white;
                color: $color-black;
            }
        }
    }
}