 /*----------------SEARCH-------------------*/
 
 ul.search {
     list-style: none;
     display: block;
     margin: 0 0px 0 0;
     padding: 0 0px 0 0;
     overflow: hidden;
 }
 
 ul.search li {
     float: left;
     margin: 0 0px 40px 0;
     width: 100%;
     background: url(../img/search.png) top right no-repeat;
     border-top: 2px solid #077dba;
     @include breakpoint(desktop) {
         width: 950px;
     }
 }
 
 ul.search li h2 {
     margin: 0px 0 0 0px;
     padding: 6px 0 0 0px;
     font-weight: normal;
     color: #808080;
     float: left;
    
     font-size: 18px;
      line-height: 26px;
     width: calc(100% - 40px);
     min-height: 50px;
     height: auto;
     @include breakpoint(tablet) {}
     @include breakpoint(desktop) {
         height: 50px;
         padding: 0px 0 0 0px;
         margin: 0px 0 0 40px;
         line-height: 50px;
         font-size: 22px;
         width: 730px;
     }
 }
 
 ul.search li p {
     margin: 0px 0 0px 0px;
     padding: 0px 0 0 0px;
     line-height: 22px;
     font-size: 16px;
     color: #808080;
     float: left;
     @include breakpoint(tablet) {}
     @include breakpoint(desktop) {
         margin: 20px 0 0 40px;
     }
 }
 
 ul.search li p.date {
     margin: 0px 0 0 0px;
     padding: 0px 0 0 0px;
     line-height: 50px;
     font-size: 22px;
     color: #bfbfbf;
     width: 180px;
     height: 50px;
     float: left;
 }
 
 ul.search h2 a:link,
 ul.search p a:link {
     color: #808080;
     text-decoration: none;
 }
 
 ul.search h2 a:link,
 ul.search p a:link {
     color: #808080;
     text-decoration: none;
 }
 
 ul.search h2 a:visited,
 ul.search p a:visited {
     color: #808080;
     text-decoration: none;
 }
 
 ul.search h2 a:hover,
 ul.search p a:hover {
     color: #808080;
     text-decoration: none;
 }
 
 ul.search h2 a:active,
 ul.search p a:active {
     color: #808080;
     text-decoration: none;
 }
