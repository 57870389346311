body.main header {
    height: 60px;
    position: relative;
    /*background-color:red;*/
    @include breakpoint(desktop) {
        height: 116px;
    }
}

header {
    height: 60px;
    position: relative;
    z-index: 11;
    @include breakpoint(desktop) {
        height: 100px;
    }
}

.top {
    position: fixed;
    background-color: #f4f4f4;
    margin: 0 0px 0 0px;
    height: 60px;
    width: 100%;
    z-index: 1001;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.40);
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        background-color: transparent;
        box-shadow: none;
        margin: 0 5px 0 5px;
        position: relative;
        height: 87px;
        width: 950px;
    }
}

#logo {
    width: 40px;
    height: 40px;
    margin: 10px 0 0 10px;
    background: url(../img/logo.png);
    background-size: 40px 40px;
    position: absolute;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 10;
    left: 0px;
    @include breakpoint(tablet) {
        margin: 10px 0 0 20px;
    }
    @include breakpoint(desktop) {
        margin: 28px 0 0 30px;
    }
}

.hamburger {
    height: 40px;
    width: 40px;
    margin: auto;
    cursor: pointer;
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 10001;
    @include breakpoint(desktop) {
        display: none;
    }
    .bar {
        height: 3px;
        width: 20px;
        left: 10px;
        display: block;
        position: absolute;
        background-color: #077dba;
    }
    .bar1 {
        top: 14px;
    }
    .bar2 {
        top: 22px;
    }
}

#wyszukiwarka {
    height: 60px;
    position: absolute;
    margin: 0px 0 0 4px;
    @include breakpoint(tablet) {
        margin: 0px 0 0 24px;
    }
    @include breakpoint(desktop) {
        right: 0px;
        margin: 20px 0 0 0;
    }
}

#wyszukiwarka input[type="text"] {
    background: none;
    border: 0px solid white;
    color: #9d9d9d;
    float: left;
    line-height: 18px;
    margin: 0;
    padding: 0px 0px 0px 6px;
    right: 0px;
    margin: 15px 0 0 0;
    font-family: 'Titillium Web', sans-serif;
    font-size: 14px;
    width: calc(100vw - 80px);
    @include breakpoint(tablet) {
        font-size: 18px;
    }
    @include breakpoint(desktop) {
        font-size: 13px;
        width: 100px;
    }
}

#wyszukiwarka input[type="text"]:focus {
    background-color: #f4f4f4;
    border: 1px solid #cccccc;
    outline: 0;
}

#wyszukiwarka input[type="submit"] {
    background: url(../img/wyszukiwarka.png) no-repeat center center;
    border: 0;
    cursor: pointer;
    float: left;
    height: 30px;
    margin: 0;
    padding: 0;
    width: 30px;
    margin: 15px 0 0 0;
    right: 0px;
}


/*---------MENU-----------------------------------------------------------------*/

nav {
    width: 100%;
    margin: 60px 0px 0 0px;
    float: left;
    background-color: #f4f4f4;
    z-index: 1000;
    min-height: calc(100vh - 130px);
    position: absolute;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        width: 950px;
        margin: 0px 5px 0 5px;
        position: static;
        min-height: auto;
    }
}


/*---------MENU 1-----------------------------------------------------------------*/

nav ul {
    padding: 0;
    margin: 0px;
    z-index: 1000;
}

nav ul li {
    display: block;
    float: left;
    position: relative;
    width: 100%;
    @include breakpoint(desktop) {
        width: auto;
        &:nth-of-type(8) {
            a {
                width: 114px;
            }
        }
    }
    &.active > .item {
        color: white;
        background: url('../img/menu2.png');
        text-decoration: none;
        background-size: 100% $nav-height-phone;
        @include breakpoint(tablet) {
            background-size: 100% $nav-height-tablet;
        }
        @include breakpoint(desktop) {
            background-size: 100% $nav-height-desktop;
        }
    }
    .item {
        color: #077dba;
        display: block;
        float: left;
        padding: 0 0px 0 10px;
        background: url('../img/menu.png');
        border-top: 1px solid #ffffff;
        border-right: 1px solid #bbbbbb;
        border-bottom: 1px solid #bbbbbb;
        width: 100%;
        text-align: left;
        margin: 0;
        box-shadow: none;
        font-size: 14px;
        background-size: 100% 40px;
        height: $nav-height-phone;
        line-height: $nav-height-phone;
        height: $nav-height-phone;
        cursor: pointer;
        @include breakpoint(tablet) {
            width: 100%;
            font-size: 18px;
            line-height: $nav-height-tablet;
            background-size: 100% $nav-height-tablet;
            height: $nav-height-tablet;
            padding: 0 0px 0 30px;
        }
        @include breakpoint(desktop) {
            padding: 0 14px 0 14px;
            text-align: center;
            width: auto;
            height: $nav-height-desktop;
            font-size: 13px;
            line-height: $nav-height-desktop;
            background-size: 100% $nav-height-desktop;
        }
        // &:link {
        //     color: #077dba;
        //     text-decoration: none;
        // }
        // &:visited {
        //     color: #077dba;
        //     text-decoration: none;
        // }
        // &:hover {
        //     color: white;
        //     background: url('../img/menu2.png');
        //     text-decoration: none;
        // }
        // &:active {
        //     color: #077dba;
        //     text-decoration: none;
        // }
        .left {
            border-top: 1px solid #ffffff;
            border-bottom: 1px solid #bbbbbb;
            @include breakpoint(desktop) {
                border-left: 1px solid #ffffff;
                border-right: 1px solid #bbbbbb;
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
            }
        }
        .right {
            @include breakpoint(desktop) {
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
            }
        }
    }
}


/*---------MENU 2-----------------------------------------------------------------*/

nav ul li ul {
    display: none;
    min-width: 100px;
    -webkit-padding-start: 0px;
    z-index: 100;
    position: relative;
    top: 0px;
    // overflow: hidden;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        // overflow: visible;
        position: absolute;
        top: 27px;
    }
    li {
        float: left;
        padding: 0;
        display: block;
        &.active > .item {
            color: white;
            background: url('../img/menu3.png');
            text-decoration: none;
            background-size: 100% $nav-height-phone;
            @include breakpoint(tablet) {
                background-size: 100% $nav-height-tablet;
            }
            @include breakpoint(desktop) {
                background-size: 100% $nav-height-desktop;
            }
        }
        .item {
            min-width: 130px;
            text-align: left;
            font-size: 14px;
            color: white;
            line-height: $nav-height-phone;
            display: block;
            float: left;
            padding: 0 0px 0 30px;
            height: $nav-height-phone;
            background: url('../img/menu2.png');
            border: 0;
            border-bottom: 1px solid #5596c8;
            background-size: 100% 40px;
            &.item--180{
                 min-width: 180px;
            }
            @include breakpoint(tablet) {
                width: 100%;
                font-size: 18px;
                line-height: $nav-height-tablet;
                background-size: 100% $nav-height-tablet;
                height: $nav-height-tablet;
                padding: 0 0px 0 40px;
            }
            @include breakpoint(desktop) {
                padding: 0 10px 0 10px;
                text-align: center;
                width: auto;
                height: $nav-height-desktop;
                font-size: 13px;
                line-height: $nav-height-desktop;
                background-size: 100% $nav-height-desktop;
            }
            // &:link {
            //     color: white;
            //     text-decoration: none;
            // }
            // &:visited {
            //     color: white;
            //     text-decoration: none;
            // }
            // &:hover {
            //     color: white;
            //     background: url('../img/menu3.png');
            //     text-decoration: none;
            // }
            // &:active {
            //     color: white;
            //     text-decoration: none;
            // }
        }
    }
}


/*---------MENU 3-----------------------------------------------------------------*/

nav ul li ul li ul {
    display: none; // !important;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    -webkit-padding-start: 0px;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        position: absolute;
        top: 0px;
        left: 150px;
        min-width: 100px;

&.ul-180{
     left: 200px;
}
       
    }
    li {
        float: left;
        padding: 0;
        display: block;
        &.active > .item {
            color: white;
            background: url('../img/menu4.png');
            text-decoration: none;
            background-size: 100% $nav-height-phone;
            @include breakpoint(tablet) {
                background-size: 100% $nav-height-tablet;
            }
            @include breakpoint(desktop) {
                background-size: 100% $nav-height-desktop;
            }
        }
        .item {
            width: 100%;
            text-align: left;
            font-size: 14px;
            color: white;
            line-height: $nav-height-phone;
            display: block;
            float: left;
            padding: 0 0px 0 50px;
            height: $nav-height-phone;
            background: url('../img/menu3.png');
            border: 0;
            border-bottom: 1px solid #5596c8;
            background-size: 100% $nav-height-phone;
            @include breakpoint(tablet) {
                width: 100%;
                font-size: 18px;
                line-height: $nav-height-tablet;
                background-size: 100% $nav-height-tablet;
                height: $nav-height-tablet;
                padding: 0 0px 0 40px;
            }
            @include breakpoint(desktop) {
                padding: 0 15px 0 15px;
                text-align: center;
                width: auto;
                height: $nav-height-desktop;
                font-size: 13px;
                line-height: $nav-height-desktop;
                background-size: 100% $nav-height-desktop;
            }
        }
    }
}

// nav ul li ul li ul li a:link {
//     color: white;
//     text-decoration: none;
// }
// nav ul li ul li ul li a:visited {
//     color: white;
//     text-decoration: none;
// }
// nav ul li ul li ul li a:hover {
//     color: white;
//     background: url('../img/menu4.png');
//     text-decoration: none;
//     background-size: 100% $nav-height-phone;
//     @include breakpoint(tablet) {}
//     @include breakpoint(desktop) {
//         background-size: 100% $nav-height-desktop;
//     }
// }
// nav ul li ul li ul li a:active {
//     color: white;
//     text-decoration: none;
// }
