/*----------------mapka-------------------*/
    
    .container_map {
        position: relative;
        /*background-color:red;*/
        // min-height: 600px;
        left: 0;
        width: 100%;
        height: 400px;
        @include breakpoint(tablet) {
            height: 600px;
        }
        @include breakpoint(desktop) {
            // width: 190px;
        }
    }
    
    #mapka {
        height: 100%;
        width: 100%;
        overflow: hidden;
        float: left;
    }
    /* SLIDER 3D*/
    
    .silder3d {
        position: relative;
        max-width: 840px;
        width: 100%;
        padding: 0 50px;
        margin: 0 auto;
        margin-top: 40px;
        height: 500px;
    }


    
    
    input[type="submit"].pokaz_dojazd {
        border: 0;
        cursor: pointer;
        float: left;
        height: 30px;
        padding: 5px;
        margin: 27px 0 10px 20px;
        background-color: #077dba;
        color: white;
    }
    
    .pokaz_dojazd_panel {
        /*background-color:pink;*/
        min-height: 60px;
        width: calc(100% - 40px);
        overflow: hidden;
        padding: 0px 0 20px 0;
        margin: 0 20px 0 20px;
        @include breakpoint(desktop) {
            margin: 0 5px 0 5px;
        }
        p {
            width: 100%;
            float: left;
            &.text {
                @include breakpoint(tablet) {
                    width: calc(100% - 160px);
                }
                @include breakpoint(desktop) {
                    width: 753px;
                }
            }
            &.p_img_skoda {
                width: 92px;
                @include breakpoint(tablet) {
                    width: 160px;
                }
                img {
                    float: right;
                    margin: 5px 20px 14px 60px;
                    border: 0;
                }
            }
            &.p_renault_i_dacia_img_skoda {
                width: 92px;
                @include breakpoint(tablet) {
                    width: 160px;
                }
                img {
                    float: right;
                    margin: 5px 0px 14px 60px;
                    border: 0;
                }
            }
            &.p_gas_specialist {
                width: 140px;
                @include breakpoint(tablet) {
                    width: 160px;
                }
                img {
                    float: right;
                    margin: 5px 20px 14px 60px;
                    border: 0;
                }
            }
        }
        body.warszatPojedynczy & {
            display: none;
        }
        input {
            width: calc(100% - 60px);
        }
        label {
            line-height: 24px;
        }
        .komunikat {
            width: 100%;
            /*background-color:red;*/
            float: left;
            padding: 20px 0 0 0;
            color: #077dba;
        }
        .miejscowosc {
            width: 70%;
            float: left;
            input {
                width: calc(100% - 30px);
            }
            @include breakpoint(tablet) {
                width: calc(25% - 10px);
            }
            @include breakpoint(desktop) {
                width: 240px;
            }
        }
        .zakres {
            width: 30%;
            float: left;
            input {
                width: calc(100% - 44px);
            }
            @include breakpoint(tablet) {
                width: calc(25% - 10px);
            }
            @include breakpoint(desktop) {
                width: 240px;
            }
        }
        #szukaj_warsztatow {
            width: calc(50% - 10px);
            margin: 20px 10px 0 0px;
            @include breakpoint(tablet) {
                width: calc(25% - 10px);
                margin: 24px 10px 0 20px;
            }
            @include breakpoint(desktop) {
                width: auto;
            }
        }
        #pokaz_wszystkie {
            width: calc(50% - 10px);
            margin: 20px 0px 0 10px;
            @include breakpoint(tablet) {
                margin: 24px 0px 0 10px;
                width: calc(25% - 10px);
            }
            @include breakpoint(desktop) {
                width: auto;
            }
        }
        span.km {
            color: #7d7d7d;
            float: left;
            font-size: 14px;
            line-height: 18px;
            margin: 0;
            padding: 6px 6px 4px 6px
        }
    }
    
    .container_box_info {
        position: absolute;
        z-index: 1;
        top: 60px;
        right: 50%;
        transform: translate(50%, 0);
        @include breakpoint(tablet) {
            right: 60px;
            transform: translate(0%, 0);
        }
    }
    
    .box_info {
        width: 230px;
        border: 2px solid #077dba;
        /*background: url(../img/key.png) no-repeat top right;*/
        background-color: white;
        overflow: hidden;
        /*background-color:#077dba;*/
        border: 5px solid #077dba;
    }
    
    .box_info p {
        width: 200px;
        line-height: 14px;
        padding: 0px 0 0 20px;
        margin: 0px;
        color: #808080;
        font-size: 12px;
        clear: both;
        /*color:white;*/
    }
    
    .box_info p.name {
        width: 177px;
        padding: 20px 0 12px 20px;
        margin: 0px;
        color: #077dba;
        font-size: 14px;
        float: left;
    }
    
    .box_info a.close {
        width: 33px;
        height: 28px;
        padding: 0px 0 18px 0px;
        display: block;
        background: url(../img/close.png) no-repeat 0px 5px;
        float: left;
        cursor: pointer;
    }