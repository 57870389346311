// Colors
$color-black: 				#111111;
$color-black-gray: 			#151515;
$color-white: 				#ffffff;
$color-white-dark: 			#acaaa9;
$color-cyan: 				#00f0ff;
$color-gray:				#7d7d7d;
$color-gray-dark:			#252525;
$color-gray-light: 			#eeeeeb;
$color-gray-light-more:		#e3e3de;
$color-gray-x:				#909090;
$color-gray-very-light: 	#efefef;
$color-gray-creme-light:	#dbdbd1;
$color-gold: 				#8f7c57;
$color-blue:				#077dba; //#0077ee;
$color-yellow:				#dec517;
$color-yellow-dark:			#d6ae00;
$color-creme:				#f8faf4;
$color-blue-light:			#77c1f9;
$color-red:					#df1a23;
$color-access:     			#FCCA00;
$color-orange:				#f2e5d4;
$color-purple:				#7260a8;


$color-select-bg: #D8D8D8;
$color-select-text: $color-white;
$opacity-hover: 0.7;




// Fonts
$font-regular: "Yantramanav";
$font-serif: "playfair_display";

// Sizes
$size-desktop-hd:1920px;
$size-desktop: 1024px;
$size-tablet: 768px;
$size-iphone6: 375px;
$min-width: 320px;



$nav-height-phone: 40px;
$nav-height-tablet: 60px;
$nav-height-desktop: 25px;

// Images that need fallback
$svg-images: ();


// Animations
$time: 0.5s;
$time-fast: 0.2s;


$ease: 		ease;
$duration: 400ms;




$ease-in: cubic-bezier(0.895, 0.03, 0.685, 0.22); //quart
$ease-out: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$easing: $ease-out;

$opacity-time: 0.2s;
$opacity-easing: ease;
