    html {
        height: 100%;
        overflow-x: hidden;
        &:not(.show__menu) {
            -webkit-overflow-scrolling: touch;
        }
    }

    html,
    body {
        min-height: 100%;
        overflow-x: hidden;
    }

    body {
        min-width: 320px;
        margin: 0;
        padding: 0;
        background-color: #f4f4f4;
        font-size: 16px;
        font-family: 'Titillium Web', sans-serif;
        color: black;
        @include breakpoint(desktop) {
            min-width: 960px;
        }
    }

    body {
        &:before {
            content: "phone";
            opacity: 0;
            font-size: 0;
            width: 0;
            height: 0;
            position: absolute;
            z-index: -1;
            overflow: hidden;
        }
        @include breakpoint(tablet) {
            &:before {
                content: "tablet";
            }
        }
        @include breakpoint(desktop) {
            &:before {
                content: "desktop";
            }
        }
    }

    body.main {
        background-color: #f4f4f4;
        height: 100%;
        @include breakpoint(desktop) {
            background: url(../img/main_background.png) top center no-repeat;
            background-color: #f4f4f4;
            overflow: visible;
        }
    }

    .hide-phone {
        display: none !important;
        @include breakpoint(tablet) {
            display: block !important;
        }
    }

    .hide-phone-tablet {
        display: none !important;
        @include breakpoint(desktop) {
            display: block !important;
        }
    }

    .hide-tablet {
        display: block !important;
        @include breakpoint(tablet) {
            display: none !important;
        }
        @include breakpoint(desktop) {
            display: block !important;
        }
    }

    .hide-tablet-desktop {
        display: block !important;
        @include breakpoint(tablet) {
            display: none !important;
        }
    }

    .hide-desktop {
        display: block !important;
        @include breakpoint(desktop) {
            display: none !important;
        }
    }

    .wrapper {
        position: relative;
        @include breakpoint(desktop) {
            overflow: hidden;
        }
    }

    body.main .wrapper {
        height: auto !important;
        height: 100%; // margin: 0 auto -126px;
        // @include breakpoint(tablet) {
        min-height: calc(100% - 86px); //}
        @include breakpoint(desktop) {
            min-height: 100%;
            margin: 0 auto -120px;
        }
    }

    body.main .push {
        height: 86px;
        clear: both;
        @include breakpoint(desktop) {
            height: 120px;
        }
    }

    iframe[name='google_conversion_frame'] {
        height: 0 !important;
        width: 0 !important;
        line-height: 0 !important;
        font-size: 0 !important;
        margin-top: -13px;
        float: left;
    }

    a:link {
        color: #077dba;
        text-decoration: none;
    }

    a:link {
        color: #077dba;
        text-decoration: none;
    }

    a:visited {
        color: #077dba;
        text-decoration: none;
    }

    a:hover {
        color: #077dba;
        text-decoration: none;
    }

    a:active {
        color: #077dba;
        text-decoration: none;
    }

    span.error {
        font-family: 'Titillium Web', sans-serif;
        color: red;
        display: block;
        padding: 6px 0 0px 10px;
        float: left;
        width: 240px;
        font-size: 12px;
        height: 23px;
    }

    label {
        font-family: 'Titillium Web', sans-serif;
        color: #7d7d7d;
        display: block;
        margin: 0px 0 0px 0px;
        float: left;
        text-align: justify;
        width: 240px;
        font-size: 12px;
        line-height: 18px;
    }

    input[type="text"] {
        font-family: 'Titillium Web', sans-serif;
        background-color: #f4f4f4;
        border: 0px;
        border-top: 1px solid #d7d5d4;
        color: #9d9d9d;
        float: left;
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        padding: 0px 6px 0px 6px;
        width: 176px;
        margin: 0px 0 0 0;
        height: 28px;
    }

    input[type="text"]:focus {
        border: 1px solid #cccccc;
        outline: 0;
        padding: 1px 5px 0px 5px;
        width: 176px;
        height: 26px;
        /*margin:0 0 1px 0;*/
    }

    input[type="text"].error {
        border-top: 1px solid red;
        /*background-color:red;
        color:white;*/
    }

    input[type="file"].error {
        border: 1px solid red;
        /*background-color:red;
        color:white;*/
    }

    input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #515151;
    }

    input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #515151;
    }

    input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #515151;
    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #515151;
    }

    input.error::-webkit-input-placeholder {
        /* WebKit browsers */
        color: white;
    }

    input.error:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: white;
    }

    input.error::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: white;
    }

    input.error:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: white;
    }

    input[type="submit"] {
        font-family: 'Titillium Web', sans-serif;
        border: 0;
        cursor: pointer;
        float: left;
        height: 28px;
        padding: 0px 10px 0px 10px;
        margin: 22px 0 0 20px;
        background-color: #077dba;
        color: white;
    }

    input[type="submit"].zaloguj {
        font-family: 'Titillium Web', sans-serif;
        border: 0;
        cursor: pointer;
        float: left;
        height: 28px;
        padding: 0px 10px 0px 10px;
        margin: 18px 0px 10px 20px;
        background-color: #077dba;
        color: white;
        @include breakpoint(desktop) {
            margin: 18px 670px 10px 20px;
        }
    }

    .logowanie_error {
        color: red;
    }

    textarea {
        font-family: 'Titillium Web', sans-serif;
        background-color: #f4f4f4;
        border: 0px;
        border-top: 1px solid #d7d5d4;
        padding: 5px;
        margin: 20px 0px 30px 20px;
        width: 536px;
        height: 316px;
        color: #9d9d9d;
    }

    textarea[type="text"].error {
        border: 0px;
        border-top: 1px solid red;
        /*background-color:red;
        color:white;*/
    }

    textarea::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #515151;
    }

    textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #515151;
    }

    textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #515151;
    }

    textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #515151;
    }

    textarea.error::-webkit-input-placeholder {
        /* WebKit browsers */
        color: white;
    }

    textarea.error:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: white;
    }

    textarea.error::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: white;
    }

    textarea.error:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: white;
    }

    input[type="checkbox"] {
        width: auto;
        float: left;
        color: white;
        height: 20px;
        border: 0;
    }
    /*----------------COINTENER-------------------*/

    .container {
        margin-left: auto;
        margin-right: auto;
        width: 100%; // background-color:#e0e0e0;
        display: block;
        @include breakpoint(tablet) {
            width: 100%; //    background-color:red;
        }
        @include breakpoint(desktop) {
            width: 960px; //    background-color:red;
        }
        /*overflow:hidden;*/
        /*background-color:white;
    -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.40);
    -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.40);
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.40);
    -webkit-transition: opacity 0.25s linear;
    -moz-transition: opacity 0.25s linear;
    -o-transition: opacity 0.25s linear;
    transition: opacity 0.25s linear;*/
    }
    /*----------------CONTENT-------------------*/

    .container_content {
        clear: both;
        background-color: white;
        padding: 0px 0 20px 0;
    }

    .container_content.only_breadcrumbs {
        min-height: 100px;
    }

    .container_content.simple {
        padding: 0px;
        margin: 0px;
    }

    .container_breadcrumbs {
        background-color: white;
        padding: 10px 0 20px 0;
        height: 0px;
        /*background-color:red;*/
        @include breakpoint(tablet) {
            height: 40px;
        }
    }

    .clear {
        clear: both;
        display: block;
        overflow: hidden;
        visibility: hidden;
        width: 0;
        height: 0;
    }

    section {
        display: block;
        float: left;
    }

    pre {
        display: block;
        float: left;
    }

    h1 {
        color: #3c3c3c;
        font-weight: 400;
        margin: 0px;
        font-size: 20px;
        line-height: 26px;
        padding: 10px 0 10px 0;
        @include breakpoint(tablet) {
            font-size: 30px;
            line-height: 36px;
            padding: 10px 0 10px 0;
        }
        @include breakpoint(desktop) {
            font-size: 30px;
            line-height: 36px;
            padding: 10px 0 10px 0;
        }
    }

    h1.blue {
        color: #077dba;
    }

    h1.patka {
        border-top: 2px solid #077dba;
        background-color: white;
        overflow: hidden;
        color: #808080;
        font-size: 22px;
        padding: 10px 0 0 40px;
        margin: 10px 0 0 0;
    }

    h2 {
        color: #808080;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin: 0px;
        padding: 10px 0 10px 0;
        font-weight: bold;
    }

    p {
        margin: 0px;
        padding: 10px 0px 10px 0px;
        color: #3c3c3c;
        font-size: 14px;
        line-height: 24px;
    }

    .normal {
        color: #3c3c3c;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        margin: 0px 20px 0px 20px;
        width: calc(100% - 40px);
        @include breakpoint(tablet) {}
        @include breakpoint(desktop) {
            margin: 0px 5px 0px 5px;
            width: auto;
        }
        &>img {
            padding: 0 20px;
            width: calc(100% - 40px);
            @include breakpoint(tablet) {
                width: calc(50% - 50px);
                display: inline-block;
                margin: 10px 0px 10px 0px;
            }
            @include breakpoint(desktop) {
                width: auto;
            }
        }
        &.systemy__gazowe {
            &>img {
                padding: 0px;
                width: 100%;
                @include breakpoint(tablet) {
                    width: 100%;
                    display: inline-block;
                    margin: 10px 0px 10px 0px;
                }
                @include breakpoint(desktop) {
                    width: auto;
                }
            }
        }
        .description {
            margin: 0px 20px 0 0;
            padding: 0px 20px 0 0px;
            line-height: 20px;
            font-size: 14px;
            color: #808080;
            float: left;
            width: 100%;
            @include breakpoint(tablet) {
                width: 50%;
                display: inline-block;
                margin: 0;
                padding: 0;
            }
            @include breakpoint(desktop) {
                width: 440px;
            }
        }
    }

    .tekst {
        /*text-align:center;*/
        text-align: justify;
        color: #3c3c3c;
        margin: 0px;
        padding: 0px;
        strong{
            font-weight:700;
        }
        h1 {
            text-align: center;
            display: block;
            padding: 20px 20px 20px 20px;
            @include breakpoint(tablet) {
                padding: 40px 50px 40px 50px;
            }
            @include breakpoint(desktop) {
                padding: 40px 50px 40px 50px;
            }
        }

        h2 {
            color: #3c3c3c;
            display: block;
            padding: 20px 20px 20px 20px;
            @include breakpoint(tablet) {
                padding: 40px 50px 40px 50px;
            }
            @include breakpoint(desktop) {
                padding: 40px 50px 40px 50px;
            }
        }

        h3 {
            color: #3c3c3c;
            display: block;
            padding: 20px 20px 0px 20px;
            @include breakpoint(tablet) {
                padding: 40px 50px 0px 50px;
            }
            @include breakpoint(desktop) {
                padding: 40px 50px 0px 50px;
            }
        }
        .tekst__button__container {
            text-align: center;
            width: 100%;
            .tekst__button {
                background-color: $color-blue;
                color: $color-white;
                text-align: center;
                display: inline-block;
                padding: 10px;
                cursor: pointer;
            }
        }

        p {
            margin: 10px 20px 10px 20px;
            @include breakpoint(tablet) {
                margin: 10px 50px 10px 50px;
            }
            @include breakpoint(desktop) {
                margin: 10px 50px 10px 50px;
            }
            img {
                max-width: 100%;
                height: auto;
            }
            &.list__p {
                padding: 0px 0px 0px 0px;
                margin: 2px 20px 2px 20px;
                @include breakpoint(tablet) {
                    margin: 2px 50px 2px 50px;
                }
                @include breakpoint(desktop) {
                    margin: 2px 50px 2px 50px;
                }
            }
        }

        .description {
            margin: 0px 20px 0 0;
            padding: 0px 20px 0 0px;
            line-height: 20px;
            font-size: 14px;
            color: #808080;
            float: left;
            width: 100%;
            @include breakpoint(tablet) {
                width: 50%;
                display: inline-block;
                margin: 0;
                padding: 0;
            }
            @include breakpoint(desktop) {
                width: 440px;
            }
        }
        &>img {
            padding: 0 20px;
            width: calc(100% - 40px);
            @include breakpoint(tablet) {
                width: calc(50% - 50px);
                display: inline-block;
                margin: 10px 0px 10px 0px;
            }
            @include breakpoint(desktop) {
                width: auto;
            }
        }
        .description.w_950 {
            margin: 0px 0px 0 0;
            padding: 0px 0px 0 0px;
            width: 950px;
        }

        .tekst__items {
            // background: red;
            margin: 10px 20px 10px 20px;
            @include breakpoint(tablet) {
                margin: 10px 50px 10px 50px;
            }
            .tekst__item {
                //   background: green;
                display: inline-block;
                border-bottom: 1px solid $color-gray-light-more;
                margin: 0;
                padding: 5px 0;
                font-size: 0;

                .tekst__item__data {
                    display: inline-block;
                    font-size: 14px;
                    line-height: 24px;
                    vertical-align: top;
                    padding: 0 5px;
                    text-align: left;
                    &.tekst__item__data--66 {
                        width: 100%;
                        @include breakpoint(tablet) {
                            width: calc(66% - 10px);
                        }
                    }
                    &.tekst__item__data--50 {
                        width: 100%;
                        @include breakpoint(tablet) {
                            width: calc(50% - 10px);
                        }
                    }
                    &.tekst__item__data--33 {
                        width: 100%;
                        @include breakpoint(tablet) {
                            width: calc(33% - 10px);
                        }
                    }
                    &.tekst__item__data--25 {
                        width: 100%;
                        @include breakpoint(tablet) {
                            width: calc(25% - 10px);
                        }
                    }
                }

                width: 100%;
                @include breakpoint(tablet) {}
                @include breakpoint(desktop) {}
            }
        }
    }

    .tekst ul {
        margin: 10px 50px 10px 50px;
    }

    .tekst .ramka {
        border: 2px dashed #51b7ee;
        padding: 10px 10px 10px 10px;
    }
    /*
.wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -260px;
}
.push {
    height:260px;
    clear: both;
}
*/
    /*----------------lang-------------------*/

    .lang {
        width: 54px;
        height: 18px;
        /*background-color:red;*/
        float: right;
    }

    .lang_pl,
    .lang_en,
    .lang_ru {
        background: url(../img/lang_background.jpg) no-repeat;
        width: 15px;
        height: 17px;
        display: block;
        float: right;
        color: #bfbfbf;
        font-size: 10px;
        padding: 1px 0 0 3px;
    }

    .lang_pl.active,
    .lang_en.active,
    .lang_ru.active {
        background: url(../img/lang_background.jpg) no-repeat 0px -18px;
        color: white;
    }
    /*SLIDER LOOP*/

    #slider_loop_left {
        width: 15px;
        height: 248px;
        background: url(../img/slider_loop_left.png);
        position: absolute;
    }

    #slider_loop_right {
        width: 15px;
        height: 248px;
        background: url(../img/slider_loop_right.png);
        position: absolute;
        right: 0px;
    }

    #slider_loop_left_3d {
        width: 60px;
        height: 214px;
        background: url(../img/slider_loop_left_3d.png);
        position: absolute;
        top: 20px;
    }

    #slider_loop_right_3d {
        width: 60px;
        height: 214px;
        background: url(../img/slider_loop_right_3d.png);
        position: absolute;
        right: 0px;
        top: 20px;
    }

    #slider_loop {
        width: 1200px;
        height: 248px;
        overflow: hidden;
        position: relative;
        margin: 0 0 0px 0;
    }

    #slider_loop ul {
        list-style: none;
        width: 1400px;
        height: 172px;
        margin: 39px 0 0 -100px;
        padding: 0;
        background-color: white;
        /*background: url(../img/slider_loop_tlo.png);*/
        overflow: hidden;
        border: 1px solid #a0a0a0;
    }

    #slider_loop ul li {
        float: left;
        text-decoration: none;
        margin: 20px 0px 0 0;
        width: 100px;
    }

    #slider_loop ul li img {
        border: 0;
    }

    #slider_loop ul li:hover img {
        height: 140px;
    }

    .item {
        width: 280px;
        height: 180px;
        margin: 10px;
        float: left;
        background-color: white;
        overflow: hidden;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.40);
        transition: opacity 0.25s linear;
    }

    .item.otwarcie {
        width: 1200px;
        height: 40px;
        background-color: #4ec1ef;
        margin-top: 80px;
    }

    p.otwarcie {
        color: white;
        font-size: 20px;
        line-height: 40px;
        margin: 0 auto;
        padding: 0 0 0 20px;
    }
    /* TEKST */

    article.tekst {
        overflow: hidden;
        background-color: white;
        overflow: hidden;
    }

    article.tekst header {
        background-color: #4ec1ef;
        color: white;
        font-size: 30px;
        font-weight: 300;
        margin: 0px;
        position: static;
        width: 1200px;
        float: left;
        overflow: hidden;
        display: block;
    }

    article.tekst header p.title {
        font-size: 30px;
        font-weight: 300;
        color: white;
        margin: 0px;
        position: static;
        width: 560px;
        height: 60px;
        line-height: 60px;
        float: left;
        margin: 0;
        padding: 0 20px 0 20px;
    }

    article.tekst section {
        /*background-color:yellow;*/
        width: 1200px;
        float: left;
        margin: 0px 0 20px 0px;
    }

    article.tekst p {
        font-size: 14px;
        color: black;
        line-height: 20px;
        display: block;
        padding: 20px 20px 0px 20px;
    }

    article.tekst ul,
    article.tekst ol {
        line-height: 20px;
        padding-left: 15px;
        margin: 20px 0 0px 20px;
    }

    article.tekst ul {
        list-style: disc;
    }

    article.tekst ol {
        list-style: decimal;
    }

    article.tekst li {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .representative_content,
    .departments_content {
        display: block;
        float: left;
        color: #0081A5;
        overflow: hidden;
        cursor: pointer;
        margin: 0 0 20px 0;
        width: 100%;
        @include breakpoint(desktop) {
            width: 960px;
        }
    }

    .representative_content .arrow,
    .departments_content .arrow {
        border-top: 2px solid #077dba;
        width: 40px;
        height: 40px;
        float: left;
        display: none;
        @include breakpoint(desktop) {
            display: block;
        }
    }

    .representative_content .arrow img,
    .departments_content .arrow img {
        margin: 17px 0px 0 7px;
    }

    .representative_content h1,
    .departments_content h1 {
        border-top: 2px solid #077dba;
        background: url(../img/representative.png) no-repeat top right;
        background-color: white;
        overflow: hidden;
        color: #808080;
        padding: 4px 0 0 0px;
        margin: 0 0px 0 0px;
        width: 100%;
        font-size: 18px;
        @include breakpoint(desktop) {
            width: 910px;
            font-size: 22px;
        }
    }

    .departments_content h1 {
        background: url(../img/departments.png) no-repeat top right;
    }
    /*
.page-nav li a.active:before {content:"["; margin-right:4px; } 
.page-nav li a.active:after {content:"]"; margin-left:4px; }
*/
    /*----------------form_newsletter-------------------*/

    #form_newsletter {
        margin: 30px 20px 0 0;
        padding: 0px 0 0 0px;
        line-height: 20px;
        font-size: 14px;
        color: #808080;
        float: left;
        width: 960px;
    }

    #form_newsletter .error {
        width: 160px;
    }

    #form_newsletter label.zgoda {
        width: 910px;
        margin: 10px 0px 0 0;
    }

    #form_newsletter label.zgoda p {
        font-size: 10px;
        line-height: 12px;
        padding: 0px;
    }

    #form_newsletter label.zgoda.error {
        border: 1px solid red;
        color: white;
    }

    #form_newsletter input[type="checkbox"] {
        width: 20px;
        padding: 0px;
        margin: 10px 10px 0px 10px;
    }

    #form_newsletter input[type="submit"] {
        margin: 20px 0px 0px 0px;
        clear: both;
        display: block;
        width: 185px;
    }
    /*---------------------icon --------------*/

    a.icon_fb {
        background: url(../img/footer_social.png) no-repeat;
        width: 26px;
        height: 26px;
        margin: 0 4px 0 0;
    }

    a.icon_yt {
        background: url(../img/footer_social.png) no-repeat -59px 0px;
        width: 26px;
        height: 26px;
        margin: 0 4px 0 0;
    }

    a.icon_gp {
        background: url(../img/footer_social.png) no-repeat -149px 0px;
        width: 26px;
        height: 26px;
        margin: 0 4px 0 0;
    }

    a.icon_app_store {
        background: url(../img/footer_social.png) no-repeat -177px 0px;
        width: 26px;
        height: 26px;
        margin: 0 4px 0 0;
    }



    .popup {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        z-index: 100;
        display: none;
        opacity: 0;

        .popup__center {
            width: 80%;
            height: 300px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: $color-creme;
            position: absolute;
            border-radius: 10px;
            @include breakpoint(desktop) {
                width: 600px;
                height: 400px;
            }
            .popup__closer {
                width: 40px;
                height: 40px;
                position: absolute;
                top: 4px;
                right: 4px;
                cursor: pointer;
                background: url(../img/closer.png) no-repeat center center;
                background-size: 24px 24px;
            }
            .popup__center__vertical {
                display: table;
                width: 100%;
                height: 100%;

                p {
                    text-align: center;
                    display: table-cell;
                    vertical-align: middle;
                    padding: 40px 40px;
                    margin: 0;
                    text-align: center;
                    span.title {
                        color: #51b7ee;
                        font-size: 26px;
                        line-height: 28px;
                        font-weight: 700;
                        display: inline-block;
                        padding-bottom: 30px;
                        width: 100%;
                    }
                    span.text {
                        font-size: 18px;
                        line-height: 28px;
                        color: #3c3c3c;
                    }
                }
            }
        }
    }


.boxs-text{
    font-size: 0;
    text-align: center;
}

.box-text{
    width: 100%;
    display: inline-block;
    text-align: center;
    vertical-align: top;
     max-width: 400px;
}
.box-text p{
    padding: 0 10px;
    margin: 10px 0;
  
}
@media (min-width: 768px) {
     .box-text{width: 50%; }
      max-width: inherit;
}

@media (min-width: 1024px) {
   .box-text{width: 25%; }
     max-width: inherit;
}