// Responsive breakpoints
@mixin breakpoint($point) {
    @if $point==phone6 {
        @media (min-width: $size-iphone6) {
            @content;
        }
    }
    @else if $point==tablet {
        @media (min-width: $size-tablet) {
            @content;
        }
    }
    @else if $point==desktop {
        @media (min-width: $size-desktop) {
            @content;
        }
    }
    @else if $point==phone-only {
        @media (max-width: $size-tablet - 1px) {
            @content;
        }
    }
    @else if $point==tablet-only {
        @media (min-width: $size-tablet) and (max-width: $size-desktop - 1px) {
            @content;
        }
    }
    @else if $point==not-desktop {
        @media (max-width: $size-desktop - 1px) {
            @content;
        }
    }
}
