 /*CZEŚĆI*/
    
    img.system_logo {
        float: right;
        margin: 20px 0 0 0;
    }
    
    .parts_content {
        display: block;
        float: left;
        color: #0081A5;
        overflow: hidden;
        cursor: pointer;
        margin: 0 0 20px 0;
        width: 100%;
        @include breakpoint(tablet) {}
        @include breakpoint(desktop) {
            width: 950px;
        }
    }
    
    .parts_content .arrow {
        border-top: 2px solid #077dba;
        width: 40px;
        height: 40px;
        float: left;
    }
    
    .parts_content.gray .arrow {
        border-top: 2px solid #c5c5c5;
    }
    
    .gray .parts_content .arrow img,
    .parts_content .arrow img {
        margin: 17px 0px 0 7px;
    }
    
    .parts_content h1 {
        border-top: 2px solid #077dba;
        background: url(../img/part-blue.png) no-repeat top right;
        background-color: white;
        overflow: hidden;
        color: #808080;
        margin: 0 0px 0 0px;
        width: calc(100% - 80px);
        font-size: 14px;
        position: relative;
        padding: 4px 40px 0 0px;
        @include breakpoint(tablet) {}
        @include breakpoint(desktop) {
            font-size: 18px;
            width: 910px;
            padding: 4px 0 0 0px;
        }
    }
    
    .parts_content.gray h1 {
        border-top: 2px solid #c5c5c5;
    }
    
    .parts_content.gray h1 {
        background: url(../img/part-gray.png) no-repeat top right;
    }
    
    .parts_content h1 span {
        /*background-color:red;*/
        float: right;
        font-size: 18px;
        width: 28px;
        height: 26px;
        text-align: center;
        color: white;
        line-height: 20px;
        position: absolute;
        top: 3px;
        right: 0;
        @include breakpoint(desktop) {
            position: relative;
        }
    }
    
    .parts_content .description {
        margin: 20px 0px 0 0;
        padding: 0px 20px 0 40px;
        line-height: 20px;
        font-size: 14px;
        color: #808080;
        float: left;
        /*background-color:red;*/
        width: calc(100% - 60px);
        @include breakpoint(desktop) {
            width: 520px;
            margin: 0px 0px 0 0;
            padding: 0px 20px 0 20px;
        }
    }
    
    .parts_content .foto {
        margin: 0px 20px 0 0;
        padding: 0px 0 0 0px;
        line-height: 20px;
        font-size: 14px;
        color: #808080;
        float: left;
        width: 100%;
        @include breakpoint(desktop) {
            width: 360px;
        }
        /*background-color:green;*/
    }
    
    .parts_content .foto img {
        margin: 40px;
        float: left;
        max-width: 80%;
        @include breakpoint(desktop) {
            max-width: auto;
            margin: 0;
            float: right;
        }
    }
    
    .schema {
        width: 950px;
        height: 450px;
        position: relative;
        float: left;
        margin: 40px 0 40px 0;
        transform: translate(-48%, 0) scale(0.8);

@include breakpoint(tablet) {
            transform: translate(-18%, 0) scale(0.8);
        }

        @include breakpoint(desktop) {
            transform: translate(0%, 0) scale(1);
        }
    }
    
    .box {
        min-height: 100px;
        width: 100%;
        @include breakpoint(desktop) {
            width: 950px;
        }
    }
    
    a.nr {
        width: 39px;
        height: 42px;
        font-size: 15px;
        line-height: 26px;
        background: url(../img/bg_nr.png) no-repeat top right;
        position: absolute;
        color: white;
        text-align: right;
        padding: 0 9px 0 0;
        /*background-color:red;
        opacity:0.4;*/
        cursor: pointer;
    }
    
    a.nr:link {
        color: white;
    }
    
    a.nr:link {
        color: white;
    }
    
    a.nr:visited {
        color: white;
    }
    
    a.nr:hover {
        color: white;
    }
    
    a.nr:active {
        color: white;
    }
    
    .cylinders {
        width: 234px;
        height: 200px;
        font-size: 15px;
        background: url(../img/bg_cylinders.png) no-repeat bottom right;
        position: absolute;
        color: #7d7d7d;
        text-align: right;
        padding: 0 0px 0 0;
        /*background-color:red;
        opacity:0.4;*/
        top: 0px;
        right: 0px;
        display: none;
        @include breakpoint(tablet) {
            display: block;
        }
    }
    
    .cylinders p {
        /*background-color:red;*/
        bottom: 74px;
        position: absolute;
        right: 0px;
    }