
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0); /*disable the flash/blink on the iPad/iPhone when an onclick is fired*/ 
}

html {
    overflow-x: hidden;
    overflow-y: scroll;
}

input, textarea, button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input, textarea {
  -webkit-appearance: none;
}


/*delete border after click on firefox on apple*/
:-moz-any-link:focus {
  outline: none;
}
