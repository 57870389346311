/*----------------KONTAKT*-------------------*/

#form_kontaktowy {
    overflow: hidden;
}

#form_kontaktowy label {
    margin: 10px 0px 0px 0px;
    width: calc(100% - 10px);
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        width: 190px;
    }
}

#form_kontaktowy input {
    width: calc(100% - 10px);
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        width: 178px;
    }
}

#form_kontaktowy input.input_4 {
    padding: 5px;
    margin: 10px 0px 0px 10px;
    width: 117px;
}

#form_kontaktowy input[type="checkbox"] {
    width: 20px;
    padding: 0px;
    margin: 0px 0 0px 0px;
    background-color: #f4f4f4;
    border: 1px solid #d7d5d4;
    position: relative;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        margin: 0px 0 0px 40px;
    }
}

#form_kontaktowy input[type=checkbox]:checked {
    background-color: #077dba;
}

#form_kontaktowy input[type=checkbox]:checked:after {
    content: '✔';
    position: absolute;
    width: 12px;
    height: 18px;
    left: 4px;

}

#form_kontaktowy label.zgoda {
    display: block;
    float: left;
    text-align: justify;
    font-size: 10px;
    color: #7d7d7d;
    width: calc(100% - 40px);
    margin: 0px 0 0px 10px;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        width: 360px;
        margin: 0px 0 0px 10px;
    }
}





#form_kontaktowy input[type="radio"] {
    width: 20px;
    height:20px;
    padding: 0px;
        margin: 0px 10px 0px 0px;
    background-color: #f4f4f4;
    border: 1px solid #d7d5d4;
    position: relative;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        //margin: 0px 0 0px 40px;
    }
}
#form_kontaktowy input[type=radio]:checked {
    background-color: #077dba;
}

#form_kontaktowy input[type=radio]:checked:after {
    content: '✔';
    position: absolute;
    width: 12px;
    height: 18px;
    left: 4px;
    color: $color-white;
}
#form_kontaktowy .radio-container{
    margin: 0 0 20px 0px;
    display: inline-block;
        width: 100%;
    @include breakpoint(desktop) {
        margin: 0 0 20px 40px;
    }

    .radio-error{
        width:200px;
        height:1px;
        display: block;
        background-color: red;
        opacity:0;
    }

    &.error{
        .radio-error{
            opacity:1;
        }
    }
    label{
        width: 100%;
    }
}

#form_kontaktowy label.radio-text{
       width: 186px;
    display: inline-block;
    line-height: 20px;
    margin: 0;
    padding: 0;
    vertical-align: top;
    float: inherit;
}



#form_kontaktowy label.zgoda p {
    font-size: 10px;
    line-height: 12px;
    padding: 0px;

}
#form_kontaktowy label.zgoda--big p {
    font-size: 12px;
    line-height: 12px;
    padding: 4px 0;
}

#form_kontaktowy label.zgoda.error {
    border: 1px solid red;
    background-color: red;
    color: white;
}

#form_kontaktowy label.zgoda--big.error {
    border:0;
    border-bottom: 1px solid red;
    background-color: transparent;
    color: white;
    width: 170px;
}



#form_kontaktowy textarea {
    margin: 0px 0px 10px 0px;

    height: 223px;
    width: 100%;
    @include breakpoint(desktop) {
        width: 380px;
        margin: 0px 0px 10px 40px;
    }
}

#form_kontaktowy input[type="submit"] {
    width: 110px;
    margin: 20px 0px 20px 0px;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        margin: 20px 0px 20px 320px;
    }
}

.kontakt {
    background-color: white; // overflow: hidden;
    img {
        width: 60%;
        @include breakpoint(tablet) {
            width: 20%;
        }
        @include breakpoint(desktop) {
            width: auto;
        }
    }
}

.kontakt .left {
    float: left;
    margin: 0 0 0 20px;
    width: calc(100% - 40px);

    height: auto;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        width: 480px;
        min-height: 600px;

        margin: 0 0 0 5px;
    }
}

.kontakt .right {
    width: calc(100% - 40px);
    height: 600px;
    margin: 0 0px 0 20px;
    float: left;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        margin: 0 5px 0 0px;
        width: 470px;
        height: 600px;
    }
}

.kontakt .right .kolumna1 {
    margin: 0 0 0 0px;
    float: left;
    background-attachment: red;
    width: calc(50% - 10px);
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        width: 190px;
        margin: 0 0 0 40px;
    }
}

.kontakt .right .kolumna2 {
    float: left;
    width: calc(50% - 10px);
    margin: 0 0px 0 20px;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        width: 190px;
        margin: 0 40px 0 10px;
    }
}

.kontakt .right .kolumna_podwojna {
    width: 390px;
    margin: 0 0 0 40px;
    float: left;
}

.kontakt h1.form {
    border-top: 2px solid #077dba;
    background: url(../img/form.png) no-repeat top right;
    background-color: white;
    overflow: hidden;
    color: #808080;
    margin: 10px 0 0 0;
    font-size: 18px;
    padding: 10px 0 40px 0px;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        font-size: 22px;
        padding: 10px 0 0 40px;
    }
}

.kontakt .representative_content,
.kontakt .departments_content {
    width: calc(100% - 40px);
    margin: 0 0 10px 20px;
    @include breakpoint(desktop) {
        width: 960px;
        margin: 0 0 20px 0;
    }
}


.kontakt .box {
    /*background-color: red;*/
    overflow: hidden;
    color: #808080;
    font-size: 22px;
    margin: 0px;
    padding: 0 0px 0 45px;
    width: 195px;
    float: left;
    width: 100%;
    height: auto;

    border-bottom: 1px dashed #bfbfbf;
    @include breakpoint(tablet) {
        border-bottom: 1px dashed #bfbfbf;
        width: 195px;
        height: 180px;
    }
}

.kontakt .box.border-none {
    border-bottom: 1px dashed #bfbfbf;
    @include breakpoint(tablet) {
        border-bottom: 1px dashed #bfbfbf;
    }
    @include breakpoint(desktop) {
        border: 0;
    }
}

.kontakt .box div.border {
    margin: 30px 0px 30px 0px;
    height: auto;
    border: 0;
    @include breakpoint(tablet) {
        border-right: 1px dashed #bfbfbf;
        height: 130px;
    }
}

.kontakt .box div.border-none {
    margin: 30px 0px 30px 0px;
    height: 130px;
    height: auto;
    @include breakpoint(tablet) {
        height: 130px;
        border-right: 1px dashed #bfbfbf;
    }
    @include breakpoint(desktop) {
        border: 0;
    }
}

.kontakt .box p {
    margin: 0px 0px 0 0px;
    padding: 0 0px 0 0px;
    color: #808080;
}

.kontakt .box p.title {
    font-size: 18px;
    padding: 0px 5px 10px 0px;
}

.kontakt .box p span {
    color: #077dba;
}

.color_blue {
    color: #077dba;
}