/*----------------NEWS-------------------*/

ul.news {
    list-style: none;
    display: block;
    margin: 0 0px 0 0;
    padding: 0 0px 0 0;
    overflow: hidden;

    img {
        max-width: 100%;
        height: auto;
    }

    video {
        width: 100%;

        @include breakpoint(tablet) {
            width: auto;
        }
    }
}

ul.news li {
    float: left;
    margin: 0 0px 40px 0;
    background: url(../img/news.png) top right no-repeat;
    border-top: 2px solid #077dba;
    margin: 0 10px 40px 10;
    width: calc(100% - 2px);

    @include breakpoint(tablet) {}

    @include breakpoint(desktop) {
        margin: 0 0px 40px 0;
        width: 950px;
    }
}

ul.news li h2 {
    margin: 0px 0 0 0px;
    font-weight: normal;
    min-height: 30px;
    float: left;
    width: calc(100% - 60px);
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0 0 0px;

    @include breakpoint(desktop) {
        min-height: 50px;
        width: 730px;
        font-size: 18px;
        line-height: 50px;
        margin: 0px 0 0 40px;
        padding: 0px 0 0 0px;
    }
}

ul.news li p {
    float: left;
    padding: 0 0px 0 0;
    width: 100%;
    box-sizing: border-box;
    width: 100%;
    margin: 10px 0px 10px 0px;

    @include breakpoint(tablet) {
        width: calc(100% - 0px);
        margin: 20px 0px 0px 0px;
    }

    @include breakpoint(desktop) {
        padding: 0 70px 0 0;
        margin: 20px 0 0px 40px;
        width: 100%;
    }

    &.video__wrapper {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        padding-top: 25px;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

ul.news li p.date {
    margin: 0px 0 0 0px;
    padding: 0px 0 0 0px;
    color: $color-black-gray;
    width: 180px;
    float: left;
    line-height: 20px;
    font-size: 16px;
    height: 20px;

    @include breakpoint(desktop) {
        line-height: 50px;
        font-size: 18px;
        height: 50px;
    }
}

ul.news li ul {
    float: left;
    list-style-type: disc;
    margin: 10px 0px 20px 0px;

    @include breakpoint(tablet) {
        margin: 10px 0px 20px 20px;
    }

    @include breakpoint(desktop) {
        margin: 10px 0px 20px 40px;
    }
}

ul.news li ul li {
    float: left;
    margin: 0 0px 0px 20px;
    padding: 0 70px 0 0;
    width: 100%;
    background: none;
    border: 0;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 24px;

}

ul.news h2 a:link,
ul.news p a:link {
    color: $color-black-gray;
    text-decoration: none;
}

ul.news h2 a:link,
ul.news p a:link {
    color: $color-black-gray;
    text-decoration: none;
}

ul.news h2 a:visited,
ul.news p a:visited {
    color: $color-black-gray;
    text-decoration: none;
}

ul.news h2 a:hover,
ul.news p a:hover {
    color: $color-black-gray;
    text-decoration: none;
}

ul.news h2 a:active,
ul.news p a:active {
    color: $color-black-gray;
    text-decoration: none;
}

.page-nav {
    list-style: none;
}

.page-nav li {
    float: left;
    margin: 0 5px;
}

.page-nav li a {
    color: #58585a;
    font-size: 16px;
    border: 1px solid #808080;
    padding: 0px;
    line-height: 22px;
    display: block;
    height: 22px;
    min-width: 22px;
    text-align: center;
}

.page-nav li a.active {
    color: white;
    background-color: #808080;
}



.table-gaz-direct,
.table-gaz-system {
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    margin-left: 0px;
    width: 100%;

    @include breakpoint(desktop) {
        margin: 0px 0px 0px 50px;
    }

    .head-tr {
        height: 40px;
        text-transform: uppercase;
    }

    tr:nth-child(even) {
        background: $color-gray-light;
    }

    tr:nth-child(odd) {
        // background: #FFF
    }

    p.head {
        font-weight: 700;
        margin: 5px 0 0px 0px;
        padding: 0px;
    }

    p.row {
        margin: 5px 0 0px 0px;
        padding: 0px;
    }
}

.table-gaz-system {
    @include breakpoint(desktop) {
        margin: 0px 0px 60px 0px;
    }
}