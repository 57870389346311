   /*----------------breadcrumbs-------------------*/
   
   .breadcrumbs {
       float: left;
       border-bottom: 1px solid #ededed;
       width: 950px;
       padding-bottom: 8px;
       margin: 0px 5px 0 20px;
       line-height: 20px;
       display: none;
       @include breakpoint(tablet) {
           display: block;
       }
       @include breakpoint(desktop) {
           margin: 0px 5px 0 5px;
       }
       ul {
           list-style: none;
           margin: 8px 0 0 0;
           padding: 0 0 0 5px;
           li {
               float: left;
               margin-right: 10px;
               font-size: 12px;
               color: #bfbfbf;
               text-transform: uppercase;
               &:after {
                   content: "-";
                   font-size: 12px;
                   padding-left: 10px;
               }
               &:last-child:after {
                   content: "";
               }
               a {
                   font-size: 12px;
                   color: #707070;
                   text-decoration: none;
                   text-transform: uppercase;
                   &:hover {
                       color: #077dba;
                   }
               }
               .bread-active {
                   color: inherit;
                   color: #077dba;
               }
           }
       }
   }
