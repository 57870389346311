
    /*----------------map-group-------------------*/
    
    .map-group {
        float: left;
        margin: 40px 0px 0px 0;
        width: 950px;
        height: 350px;
        background: url(../img/map-group.jpg) top right no-repeat;
        /*background-color:red;*/
        position: relative;
        float: left;
        display: none;
        @include breakpoint(tablet) {
            display: block;
            transform: scale(0.76) translate(-144px, 0);
        }
        @include breakpoint(desktop) {
            transform: scale(1) translate(0, 0);
        }
    }
    
    .map__group__select__info {
        color: #077dba;
        font-weight: 400;
        margin: 0px;
        font-size: 16px;
        line-height: 26px;
        padding: 40px 0 10px 0;
        @include breakpoint(tablet) {
            display: none;
        }
    }
    
    .map__group__select {
        width: 100%;
        margin: 0px 0px 40px 0;
        height: 40px;
        option {
            font-size: 14px;
        }
        @include breakpoint(tablet) {
            display: none;
        }
    }
    
    .marker-group {
        position: absolute;
        margin: 0 0px 0px 0;
        width: 38px;
        height: 48px;
        background: url(../img/marker-group.png) 0px 0px no-repeat;
        display: none;
        /*background-color:red;*/
        cursor: pointer;
        @include breakpoint(tablet) {
            display: block;
        }
        @include breakpoint(desktop) {}
    }
    
    .marker-group.active {
        background: url(../img/marker-group.png) -38px 0px no-repeat;
    }
    
    .marker-group .hide {
        display: none;
    }
    
    .one-group {
        float: left;
        margin: 0px 0px 20px 0;
        width: 100%;
        min-height: 100px;
        background: url(../img/world.png) top right no-repeat;
        /*background-color:red;*/
        border-top: 2px solid #077dba;
        // @include breakpoint(tablet) {
        //     width: 950px;
        // }
        @include breakpoint(desktop) {
            width: 950px;
        }
    }
    
    .one-group h2 {
        margin: 0px 0 0 40px;
        padding: 0px 0 0 0px;
        line-height: 50px;
        font-size: 22px;
        font-weight: normal;
        width: 910px;
        height: 50px;
        float: left;
        color: #077dba;
    }
    
    .one-group p {
        margin: 20px 0 0px 40px;
        padding: 0px 0 0 0px;
        line-height: 24px;
        font-size: 14px;
        color: #808080;
        float: left;
    }