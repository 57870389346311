/*PODSTRONA JEDNEJ CZESCI*/
    
    .parts_description {
        margin: 0px 0px 0 0;
        padding: 0px 20px 0 20px;
        line-height: 20px;
        font-size: 14px;
        color: #808080;
        float: left;
        width: 520px;
        /*background-color:red;*/
    }
    
    .parts_foto {
        margin: 0px 20px 0 0;
        padding: 0px 0 0 0px;
        line-height: 20px;
        font-size: 14px;
        color: #808080;
        float: left;
        width: 360px;
        /*background-color:green;*/
    }
    
    .parts_foto img {
        float: right;
    }
    
    .hide {
        overflow: hidden;
        display: none;
    }
    