.widget_container {
    z-index:100;
    border-radius: 15px;
    width: 220px;
    min-height: 160px;
    max-height: 400px;
    background: #fff;
    position: fixed;
    bottom: 50px;
    right: 10px;
    box-shadow: 0px 0px 20px 0px rgba(150, 150, 150, 0.75);
    background: #f6f6f6;
    overflow: hidden;

    .widget_info {
        text-align: center;
        padding: 20px 20px 20px 20px;
        max-height: 300px;
        overflow: hidden;
    }

    img {
        padding: 10px 0px;
    }

    p {
        font-size: 14px;
    }

    a {

        border-radius: 16px;
        font-size: 14px;
        background: #077dba;
        color: #fff;
        text-align: center;
        display: block;
        padding: 10px;
        font-weight: bold;
        width: calc(100% - 60px);
        margin: 0px auto 20px auto;
        display: block;
    }
}