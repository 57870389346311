.videos__page {
    h1 {
        text-align: left;
        display: block;
        padding: 20px 20px 20px 20px;
        color: $color-blue;
        position: relative;
        @include breakpoint(tablet) {
            padding: 40px 50px 40px 10px;
        }
        @include breakpoint(desktop) {
            padding: 40px 50px 20px 10px;
        }
        a {
            position: absolute;
            right: 0;
            margin: 24px 0px 0 10px;
            font-family: 'Titillium Web', sans-serif;
            border: 0;
            cursor: pointer;
            float: left;
            height: 28px;
            padding: 0px 10px 0px 10px;
            margin: 20px 10px 0px 20px;
            background-color: #077dba;
            color: white;
            display: block;
            top: 0;
            font-size: 13px;
            line-height: 28px;
            @include breakpoint(tablet) {
                margin: 48px 10px 0px 20px;
            }
        }
    }
    .videos__list {
        .video__tpl {
            display: inline-block;
            vertical-align: top;
            text-align: justify;
            color: #3c3c3c;
            margin: 0px;
            padding: 0px;
            width: 100%;
            @include breakpoint(tablet) {
                width: 33%;
                &:first-child {
                    width: 100%;
                }
            }
            p {
                margin: 10px 20px 25px 20px;
                padding: 0;
                line-height: 20px;
                @include breakpoint(tablet) {
                    margin: 10px 20px 25px 10px;
                }
                @include breakpoint(desktop) {
                    margin: 10px 50px 25px 10px;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .video__tpl__container {
                padding: 25px 10px 10px 10px;
                @include breakpoint(tablet) {
                    padding: 25px 10px 10px 10px;
                }
                @include breakpoint(desktop) {
                    padding: 25px 10px 10px 10px;
                }
                .video__tpl__wrapper {
                    position: relative;
                    padding-bottom: 56.25%;
                    /* 16:9 */
                    height: 0;
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.video__page {
    /*text-align:center;*/
    text-align: justify;
    color: #3c3c3c;
    margin: 0px;
    padding: 0px;
    h1 {
        text-align: left;
        display: block;
        padding: 20px 20px 20px 20px;
        color: $color-blue;
        @include breakpoint(tablet) {
            padding: 40px 50px 40px 50px;
        }
        @include breakpoint(desktop) {
            padding: 40px 50px 20px 10px;
        }
    }
    p {
        margin: 10px 20px 25px 20px;
        padding: 0;
        line-height: 20px;
        @include breakpoint(tablet) {
            margin: 10px 50px 25px 50px;
        }
        @include breakpoint(desktop) {
            margin: 10px 50px 25px 10px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .video__container {
        padding: 25px 10px 10px 10px;
        .video__wrapper {
            position: relative;
            padding-bottom: 56.25%;
            /* 16:9 */
            height: 0;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
