
    
    /*----------------dystrybutorzy-------------------*/
    
    .css-map-container {
        transform: scale(0.6) translate(-38%, -20%);
        @include breakpoint(phone6) {
            transform: scale(0.7) translate(-24%, -20%);
        }
        @include breakpoint(tablet) {
            transform: scale(1) translate(0%, 0%);
        }
    }
    
    .dystrybutorzy .box {
        /*border-bottom: 1px dashed #bfbfbf;*/
        /*background-color: red;*/
        overflow: hidden;
        color: #808080;
        font-size: 22px;
        margin: 0px;
        /*width:195px;*/
        float: left;
        width: 100%;
        height: auto;
        padding: 0 0px 0 0px;
        border-bottom: 1px dashed #bfbfbf;
        @include breakpoint(desktop) {
            border: 0;
            width: 430px;
            height: 500px;
            padding: 0 0px 0 45px;
        }
    }
    
    .dystrybutorzy .box.line {
        width: 960px;
        height: 0px;
        border-bottom: 1px dashed #bfbfbf;
        display: none;
        @include breakpoint(desktop) {
            display: block;
        }
    }
    
    .dystrybutorzy .box.border-none {
        border-bottom: 0px;
    }
    
    .dystrybutorzy .box div.border {
        margin: 30px 0px 30px 0px;
        height: auto;
        @include breakpoint(desktop) {
            border-right: 1px dashed #bfbfbf;
            height: 430px;
        }
    }
    
    .dystrybutorzy .box div.border-none {
        margin: 30px 0px 30px 0px;
        height: auto;
        @include breakpoint(desktop) {
            margin: 30px 0px 30px 0px;
            height: 430px;
        }
    }
    
    .dystrybutorzy .box p {
        margin: 0px 0px 0 0px;
        padding: 0 20px 0 0px;
        color: #808080;
    }
    
    .dystrybutorzy .box p.title {
        font-size: 18px;
        padding: 0px 5px 10px 0px;
    }
    
    .dystrybutorzy .box p span {
        color: #077dba;
    }