footer {
    height: 280px;
    position: relative;
    background-color: #f4f4f4;
    background: url(../img/footer.png) no-repeat;
    color: #7d7d7d;
    width: calc(100% - 40px);
    padding: 0 20px;
    @include breakpoint(tablet) {}
    @include breakpoint(desktop) {
        width: 100%;
        padding: 0 0px;
    }
    .rzetelna-firma {
        width: 130px;
        margin: -26px 0 20px 10px;
        @include breakpoint(tablet) {
            margin: -26px 0 20px 40px;
        }
        @include breakpoint(desktop) {
            margin: 20px 0 20px 0;
        }
    }
    
    .bottom {
        position: relative;
        background: url(../img/footer.png) no-repeat;
        background-position-x: 50%;
        @include breakpoint(tablet) {}
        @include breakpoint(desktop) {
            height: 60px;
        }
        &.bottom--end {
            .container {
                padding-top: 30px;
                @include breakpoint(tablet) {
                    padding-top: 0px;
                }
            }
        }
        p {
            text-align: center;
            line-height: 28px;
            margin: 0;
            padding: 0;
            @include breakpoint(tablet) {}
            @include breakpoint(desktop) {
                line-height: 60px;
            }
        }
        .realization {
            margin: 20px 0px 0 0px;
            text-align: center;
            width: 100%;
            @include breakpoint(tablet) {
                width: 30%;
                text-align: left;
                margin-top: 30px;
            }
            @include breakpoint(desktop) {
                width: auto;
            }
        }
        .copyright {
            margin: 20px 0px 0 0px;
            text-align: center;
            width: 100%;
            display: inline-block;
            @include breakpoint(tablet) {
                width: 40%;
                margin-top: 30px;
            }
            @include breakpoint(desktop) {
                margin: 20px 240px 0 240px;
                width: auto;
            }
        }
        .owner {
            margin: 0px 0px 30px 0px;
            text-align: center;
            width: 100%;
            display: inline-block;
            img {
                margin: 20px 0px 0 0px;
                @include breakpoint(tablet) {
                    margin: 30px 0px 0 0px;
                }
                @include breakpoint(desktop) {
                    margin: 20px 0px 0 0px;
                }
            }
            @include breakpoint(tablet) {
                width: 30%;
                text-align: right;
            }
            @include breakpoint(desktop) {
                width: auto;
            }
        }
    }
    .column {
        float: left;
        &:nth-of-type(1) {
            width: 100%;
            height: 180px;
            @include breakpoint(tablet) {
                width: 30%;
                height: 245px;
            }
            @include breakpoint(desktop) {
                width: 200px;
                height: 280px;
            }
        }
        &:nth-of-type(3) {
            width: 100%;
            @include breakpoint(tablet) {
                width: 30%;
            }
            @include breakpoint(desktop) {
                width: 200px;
            }
        }
        &:nth-of-type(5) {
            width: 100%;
            clear: both;
            height: 240px;
            @include breakpoint(tablet) {
                width: 30%;
                clear: none;
            }
            @include breakpoint(desktop) {
                width: 240px;
            }
        }
        &:nth-of-type(7) {
            width: 100%;
            height: 260px;
            @include breakpoint(desktop) {
                width: 200px;
                height: 220px;
                height: auto;
            }
        }
    }
    .separator {
        float: left;
        background: url(../img/footer_separator.png) no-repeat;
        width: 2%;
        height: 37px;
        @include breakpoint(desktop) {
            width: 40px;
        }
        display: none;
        @include breakpoint(tablet) {
            display: block;
        } // &:nth-of-type(4) {
        //     display: none;
        //     @include breakpoint(tablet) {
        //         display: block;
        //     }
        // }
        &:nth-of-type(6) {
            display: none;
            @include breakpoint(desktop) {
                display: block;
            }
        }
    }
    a {
        font-size: 13px;
        line-height: 18px;
        display: block;
        float: left;
        padding: 0px;
        &:link {
            color: #535353;
            text-decoration: none;
        }
        &:visited {
            color: #535353;
            text-decoration: none;
        }
        &:hover {
            color: #077dba;
            text-decoration: none;
        }
        &:active {
            color: #535353;
            text-decoration: none;
        }
    }
    h1 {
        font-size: 16px;
        line-height: 18px;
        display: block;
        float: left;
        font-weight: 600;
        color: #7d7d7d;
        width: 100%;
        padding: 30px 0px 10px 0px;
        @include breakpoint(desktop) {
            padding: 10px 10px 10px 5px;
            width: auto;
        }
    }
    p {
        font-size: 13px;
        line-height: 18px;
        display: block;
        float: left;
        color: #535353;
        font-size: 12px;
        padding: 0 0px 10px 0px;
        @include breakpoint(desktop) {
            font-size: 13px;
            padding: 0 10px 10px 5px;
        }
    }
    ul {
        list-style: none;
        display: block;
        padding: 0px 0 0 0px;
        margin: 0px;
        float: left;
        @include breakpoint(desktop) {
            padding: 0px 0 0 5px;
        }
        li {
            margin-bottom: 5px;
            display: block;
            padding: 0px 0 0 0px;
            margin: 0px;
            float: left;
            width: 100%;
            a {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    .left {
        width: 600px;
        height: 40px;
        float: left;
        position: absolute;
    }
    .right {
        width: 220px;
        height: 40px;
        position: absolute;
        right: 0;
    }
    #footer_firmy_3 {
        width: 220px;
        position: relative;
    }
    #footer_logo_emmegas {
        position: absolute;
        width: 160px;
        height: 20px;
        background: url(../img/logo_emmegas.png) no-repeat;
        top: 77px;
        background-size: 140px;
        left: 0px;
        @include breakpoint(tablet) {
            left: 36px;
        }
        @include breakpoint(desktop) {
            top: 50px;
        }
    }
    #footer_logo_landirenzo {
        position: absolute;
        width: 160px;
        height: 20px;
        background: url(../img/footer_logo_landirenzo.png) no-repeat;
        top: 116px;
        left: 0px;
        @include breakpoint(tablet) {
            left: 31px;
        }
        @include breakpoint(desktop) {
            top: 87px;
        }
    }
    #footer_logo_landi {
        position: absolute;
        width: 71px;
        height: 31px;
        background: url(../img/footer_logo_landi.png) no-repeat;
        top: 132px;
        left: 0px;
        @include breakpoint(tablet) {
            left: 70px;
        }
    }
    #footer_logo_lovato {
        position: absolute;
        width: 120px;
        height: 36px;
        background: url(../img/footer_logo_lovato.png) no-repeat;
        top: 150px;
        left: 0px;
        @include breakpoint(tablet) {
            left: 49px;
        }
        @include breakpoint(desktop) {
            top: 128px;
        }
    } // #projekt_footer {
    //     width: 200px;
    // }
    // #projekt_footer span {
    //     display: block;
    //     float: left;
    // }
    // #ndc {
    //     margin: 10px 0px 10px 10px;
    //     background: url(../img/ndc.png) no-repeat;
    //     height: 20px;
    //     width: 70px;
    // }
}

body.main footer {
    bottom: 0px;
    height: 86px;
    width: 100%;
    position: relative;
    padding: 0px;
    @include breakpoint(desktop) {
        //  padding: 0 20px;
        //width: calc(100% - 40px);
        height: 120px;
    }
    a {
        font-size: 12px;
        color: white;
        line-height: 40px;
        display: block;
        float: left;
        text-transform: uppercase;
        height: 40px;
        width: auto;
        padding: 0 10px 0 10px;
        &:link {
            color: white;
            text-decoration: none;
        }
        &:visited {
            color: white;
            text-decoration: none;
        }
        &:hover {
            color: white;
            background-color: #4ec1ef;
            text-decoration: none;
        }
        &:active {
            color: white;
            text-decoration: none;
        }
    }
    .left {
        width: 600px;
        height: 40px;
        float: left;
        position: absolute;
    }
    .right {
        width: 220px;
        height: 40px;
        position: absolute;
        right: 0;
    }
    #firmy_3 {
        width: 100%;
        height: 86px;
        background: url(../img/firmy_3.png) no-repeat;
        position: relative;
        text-align: center;
        font-size: 0; //   background: red;
        @include breakpoint(desktop) {
            width: 960px;
            height: 120px;
        }
    }
    #logo_landirenzo,
    #logo_emmegas,
    #logo_landi,
    #logo_lovato {
        position: relative;
        float: inherit;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        padding: 0;
        margin-left: 2%;
        @include breakpoint(tablet) {
            margin-left: 4%;
        }
        @include breakpoint(desktop) {
            margin-left: 20px;
        }
        &:hover {
            background-color: transparent;
        }
    }
    #logo_emmegas {
        background-image: url(../img/logo_emmegas.png);
        background-size: contain;
        width: 30%;
        @include breakpoint(tablet) {
            width: 28%;
        }
        @include breakpoint(desktop) {
            width: 200px;
        }
    }
    #logo_landirenzo {
        background-image: url(../img/logo_landirenzo.png);
        background-size: contain;
        width: 36%;
        @include breakpoint(tablet) {
            width: 28%;
        }
        @include breakpoint(desktop) {
            width: 200px;
        }
    }
    #logo_lovato {
        width: 24%;
        background-image: url(../img/logo_lovato.png);
        background-size: contain;
        @include breakpoint(tablet) {
            width: 24%;
        }
        @include breakpoint(desktop) {
            width: 143px;
        }
    }
    #logo_landi {
        width: 18%;
        background-image: url(../img/logo_landi.png);
        background-size: contain;
        @include breakpoint(tablet) {
            width: 12%;
        }
        @include breakpoint(desktop) {
            width: 82px;
        }
    }
}



/*----------------newsletter-------------------*/

#newsletter {
    height: auto;
    width: 100%;
    margin: 0 15px 0 0;
    right: 0px;
    @include breakpoint(tablet) {
        height: 60px;
        width: 220px;
    }
    @include breakpoint(desktop) {}
}

#newsletter input[type="text"] {
    background-color: white;
    width: calc(100% - 40px);
    @include breakpoint(tablet) {
        width: 160px;
    }
}

#newsletter input[type="submit"] {
    background: url(../img/newsletter.png) no-repeat center center;
    border: 0;
    cursor: pointer;
    float: left;
    height: 28px;
    padding: 0;
    width: 28px;
    margin: 0px 0 0 0;
}